var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'lotcomparedata',
    {
      'is-wrapper': _vm.wrapper,
      'is-expandable': _vm.expandable,
      'is-expanded': _vm.expanded
    }
  ],attrs:{"aria-expanded":_vm.expandable ? _vm.expanded ? 'true' : 'false' : null}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"header"},[_c('h3',[_vm._t("title")],2)])])],1),_c('b-row',[_c('b-col',[(_vm.expandable)?_c('transition-expand',{attrs:{"minHeight":_vm.minHeight}},[_c('div',{key:_vm.expanded,staticClass:"lotcomparedata-content"},[_vm._t("default")],2)]):_c('div',{staticClass:"lotcomparedata-content"},[_vm._t("default")],2)],1)],1),(_vm.expandable)?_c('a',{staticClass:"lotcomparedata-expander",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleExpanded($event)}}},[_c('b-icon',{attrs:{"icon":"plus","scale":"1.8"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }