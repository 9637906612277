<template>
  <footer class="article-footer">
    <b-row align-v="center" align-h="between">
      <b-col :[$root.mobile.up.key]="'auto'" :[`order-${$root.mobile.up.key}`]="'last'">
        <nav class="article-meta">
          <ul>
            <slot name="meta"/>
          </ul>
        </nav>
      </b-col>

      <b-col :[$root.mobile.up.key]="'auto'" :[`order-${$root.mobile.up.key}`]="'first'">
        <div class="article-backlink">
          <slot name="backlink"/>
        </div>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: 'ArticleFooter'
}
</script>

<style lang="scss">
$article-footer-gap: $spacer * 3 !default;
$article-footer-padding-y: 0 !default;
$article-footer-padding-x: 0 !default;

$article-footer-meta-item-padding-y: $spacer * 0.15 !default;
$article-footer-meta-item-padding-x: $article-footer-meta-item-padding-y !default;

$article-mobile-breakpoint: $mobile-breakpoint !default;

$article-footer-backlink-mobile-gap: $spacer * 1.5 !default;

.article-footer {
  margin-top: $article-footer-gap;
  padding: $article-footer-padding-y $article-footer-padding-x;

  .article-backlink {}

  .article-meta {
    > ul {
      @include list-unstyled();
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: ($article-footer-meta-item-padding-y * -1) ($article-footer-meta-item-padding-x * -1);

      > li {
        padding: $article-footer-meta-item-padding-y $article-footer-meta-item-padding-x;
      }
    }
  }

  @include media-breakpoint-down($article-mobile-breakpoint) {
    .article-backlink {
      margin-top: $article-footer-backlink-mobile-gap;
    }
  }
}
</style>
