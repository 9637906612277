<template>
  <b-button v-if="inline" :variant="$attrs.variant || 'primary'" v-bind="$attrs"><slot/></b-button>

  <section v-else class="articleaction">
    <b-button v-bind="$attrs" :variant="$attrs.variant || 'primary'"><slot/></b-button>
  </section>
</template>

<script>
export default {
  name: 'ArticleAction',
  inheritAttrs: false,
  props: {
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$articleaction-gap: $component-gap * 0.5 !default;
$articleaction-padding-y: $articleaction-gap !default;
$articleaction-padding-x: 0 !default;
$articleaction-border: $border-width $border-style $border-color !default;
$articleaction-font-size: $font-size-sm !default;

.articleaction {
  position: relative;
  margin-top: $articleaction-gap;
  margin-bottom: $articleaction-gap;
  padding: $articleaction-padding-y $articleaction-padding-x 0;
  border-top: $articleaction-border;
  font-size: $articleaction-font-size;
  text-align: right;
}
</style>
