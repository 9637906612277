<template>
  <section
    :class="[
      'lotcomparedata',
      {
        'is-wrapper': wrapper,
        'is-expandable': expandable,
        'is-expanded': expanded
      }
    ]"
    :aria-expanded="expandable ? expanded ? 'true' : 'false' : null"
  >
      <b-row>
        <b-col>
          <div class="header">
            <h3><slot name="title"/></h3>
        </div>
      </b-col>
      </b-row>

    <b-row>
      <b-col>
        <transition-expand v-if="expandable" :minHeight="minHeight">
          <div class="lotcomparedata-content" :key="expanded">
            <slot/>
          </div>
        </transition-expand>

        <div v-else class="lotcomparedata-content">
          <slot/>
        </div>
      </b-col>
    </b-row>

    <a v-if="expandable" href="#" class="lotcomparedata-expander" @click.prevent="toggleExpanded"><b-icon icon="plus" scale="1.8"/></a>
  </section>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

const VARIANTS = [null, ''].concat(Object.keys(SCSS_VARIABLES.colors.theme))

export default {
  name: 'LotCompareTable',
  props: {
    wrapper: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: null
    },
    titleVariant: {
      type: String,
      default: null,
      validator: value => VARIANTS.includes(value)
    }
  },
  data () {
    return {
      minHeight: 100,
      expanded: this.expandable ? false : null
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss">
$lotcomparedata-gap: $component-gap * 0.5 !default;
$lotcomparedata-padding-y: $lotcomparedata-gap !default;
$lotcomparedata-padding-x: 0 !default;
$lotcomparedata-border: $border-width $border-style $border-color !default;
$lotcomparedata-font-size: $base-font-size !default;

$lotcomparedata-title-gap: 0 !default;
$lotcomparedata-title-font-size: inherit !default;

$lotcomparedata-item-gap: $lotcomparedata-font-size !default;
$lotcomparedata-item-title-gap: $lotcomparedata-gap !default;
$lotcomparedata-item-separated-border: $lotcomparedata-border !default;

$lotcomparedata-content-box-shadow-spread: $spacer !default;
$lotcomparedata-content-box-shadow: inset 0 ($lotcomparedata-content-box-shadow-spread * -0.75) $lotcomparedata-content-box-shadow-spread ($lotcomparedata-content-box-shadow-spread * 0.25) $white !default;
$lotcomparedata-content-transition-in: all ease-out 400ms !default;
$lotcomparedata-content-transition-out: all ease-out 300ms !default;

$lotcomparedata-expander-gap: $lotcomparedata-title-gap * 0.5 !default;
$lotcomparedata-expander-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 500ms !default;
$lotcomparedata-expander-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

$lotcomparedata-mobile-breakpoint: $mobile-breakpoint !default;

$lotcomparedata-mobile-title-gap: $spacer !default;

.lotcomparedata {
  position: relative;
  margin-top: $lotcomparedata-gap;
  margin-bottom: $lotcomparedata-gap;
  padding: $lotcomparedata-padding-y $lotcomparedata-padding-x 0;
  font-size: $lotcomparedata-font-size;

  .newvalue {
    margin-bottom: $lotcomparedata-gap * 0.5;
  }

  .first-col {
    vertical-align: top;
  }

  .oldvalue {
    color:$gray-600;
  }

  .header {
    border-bottom: $lotcomparedata-border;
    margin-bottom: calc(#{$lotcomparedata-item-title-gap} - #{$lotcomparedata-item-gap});
  }

  .lotcomparedata-title {
    margin-bottom: $lotcomparedata-title-gap;
    font-size: $lotcomparedata-title-font-size;
    text-align: left;
    white-space: normal;

    > * {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      color: inherit;
      line-height: inherit;
    }
  }

  &:not(.is-wrapper) {
    .lotcomparedata-content {
      dl {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        > dt,
        > dd {
          flex: 0 0 50%;
          max-width: 50%;
          margin: 0;
          padding-top: $lotcomparedata-item-gap;
          text-overflow: ellipsis;
          overflow: hidden;

          .subject-information{
            margin-bottom:10px;
            &:last-child{
              margin-bottom:0;
            }
          }
        }

        &.separated {
          > dt,
          > dd {
            padding-bottom: $lotcomparedata-item-gap;
            border-bottom: $lotcomparedata-item-separated-border;
          }

          > dt:nth-last-child(2) {
            padding-bottom: 0;
            border-bottom: 0;

            + dd {
              padding-bottom: 0;
              border-bottom: 0;
            }
          }
        }

        > dt:first-child {
          padding-top: 0;

          + dd {
            padding-top: 0;
          }
        }

        > dt{
          padding-right: $lotcomparedata-item-gap;
        }
      }

      table {
        table-layout: fixed;
        width: 100%;

        thead,
        tbody,
        tfoot {
          > tr {
            > th,
            > td {
              padding-top: $lotcomparedata-item-gap;
            }
          }

          &:first-child {
            > tr {
              &:first-child {
                > th,
                > td {
                  padding-top: 0;
                }
              }
            }
          }
        }

        thead > tr,
        tbody > tr.thead-tr,
        tfoot > tr.thead-tr {
          > th {
            padding-top: $lotcomparedata-item-title-gap;
            padding-bottom: $lotcomparedata-item-gap;
            border-bottom: $lotcomparedata-item-separated-border;
          }
        }
      }

      > *:last-child {
        margin-bottom: 0;
      }

      &.expand-enter-active {
        transition: $lotcomparedata-content-transition-in;
      }

      &.expand-leave-active {
        transition: $lotcomparedata-content-transition-out;
      }
    }

    .lotcomparedata-expander {
      display: block;
      float: right;
      margin-top: $lotcomparedata-expander-gap;
      transition: $lotcomparedata-expander-transition-out;
    }

    &.is-expandable {
      .lotcomparedata-content {
        position: relative;
        overflow: hidden;

        &:before {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          left: $lotcomparedata-content-box-shadow-spread * -2;
          right: $lotcomparedata-content-box-shadow-spread * -2;
          height: 200%;
          box-shadow: $lotcomparedata-content-box-shadow;
          transition: inherit;
        }

        &.expand-enter,
        &.expand-leave-to {
          &:before {
            transform: translateY($lotcomparedata-content-box-shadow-spread * 0);
          }
        }

        &.expand-enter-to,
        &.expand-leave {
          &:before {
            transform: translateY($lotcomparedata-content-box-shadow-spread * 2);
          }
        }

        &.expand-state-open {
          &:before {
            display: none;
          }
        }
      }

      &.is-expanded {
        .lotcomparedata-expander {
          transform: rotate(-135deg);
          transition: $lotcomparedata-expander-transition-in;
        }
      }
    }
  }

  .form-control, .custom-select, .custom-control, .b-custom-control { font-size: $input-font-size; }
  .form-control-sm, .custom-select-sm, .custom-control-sm, .b-custom-control-sm { font-size: $input-font-size-sm; }
  .form-control-lg, .custom-select-lg, .custom-control-lg, .b-custom-control-lg { font-size: $input-font-size-lg; }

  @include media-breakpoint-down($lotcomparedata-mobile-breakpoint) {
    .lotcomparedata-title {
      margin-bottom: $lotcomparedata-mobile-title-gap;
    }

    &:not(.is-wrapper){
      .lotcomparedata-content {
        dl {
          display: block;

          > dt,
          > dd {
            max-width: none;
          }

          > dd {
            padding-top: 0;
          }

          &.separated {
            > dt {
              padding-bottom: 0;
              border-bottom: 0 none;
            }
          }
        }

        table {
          display: block;

          > thead,
          > tfoot {
            display: none;
          }

          > tbody {
            display: block;

            > tr {
              display: block;

              > th ,
              > td {
                display: block;
              }

              > th {
                ~ td {
                  padding-top: 0;

                  &[data-th] {
                    &:before {
                      display: block;
                      content: attr(data-th);
                      padding-top: $lotcomparedata-item-gap;
                      font-weight: $table-th-font-weight;
                    }

                    &:last-child {
                      margin-bottom: calc(#{$lotcomparedata-item-title-gap} - #{$lotcomparedata-item-gap});
                    }
                  }
                }

                + td {
                  &[data-th] {
                    &:before {
                      margin-top: $lotcomparedata-item-gap;
                      border-top: $lotcomparedata-item-separated-border;
                    }
                  }
                }
              }

              &:first-child {
                > th,
                > td {
                  &:first-child {
                    padding-top: 0;
                  }
                }
              }

              &:last-child {
                > th {
                  ~ td {
                    &[data-th] {
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
