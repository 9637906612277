<template>
  <li :class="[{ 'is-title': isTitle }]">
    <component class="article-meta-item" :is="tag" v-bind="$attrs"><slot/></component>
  </li>
</template>

<script>
export default {
  name: 'ArticleMetaItem',
  inheritAttrs: false,
  computed: {
    tag () {
      return Object.keys(this.$attrs).includes('href') ? 'a' : 'span'
    },
    isTitle () {
      return this.tag === 'span'
    }
  }
}
</script>

<style lang="scss">
$article-meta-item-gap: $spacer * 1.5 !default;

$article-meta-item-link-size: $font-size-base * 2 !default;
$article-meta-item-link-bg: $primary !default;
$article-meta-item-link-font-size: 100% !default;
$article-meta-item-link-color: $white !default;
$article-meta-item-link-hover-bg: darken($primary, 5%) !default;

$article-meta-item-span-gap: $spacer * 0.25 !default;
$article-meta-item-span-font-weight: $font-weight-bold !default;

$article-meta-item-mobile-breakpoint: $mobile-breakpoint !default;

$article-meta-item-mobile-gap: $spacer * 0.5 !default;

.article-meta {
  > ul {
    >li {
      > a.article-meta-item  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $article-meta-item-link-size;
        height: $article-meta-item-link-size;
        background-color: $article-meta-item-link-bg;
        font-size: $article-meta-item-link-font-size;
        color: $article-meta-item-link-color;
        border-radius: 50%;
        text-decoration: none;

        &:hover {
          background-color: $article-meta-item-link-hover-bg;
        }
      }

      > span.article-meta-item  {
        padding-left: $article-meta-item-span-gap;
        padding-right: $article-meta-item-span-gap;
        font-weight: $article-meta-item-span-font-weight;
      }

      &.is-title {
        margin-left: $article-meta-item-gap;

        &:first-child {
          margin-left: 0;
        }
      }

      @include media-breakpoint-down($article-meta-item-mobile-breakpoint) {
        &.is-title {
          margin-left: 0;
          margin-top: $article-meta-item-mobile-gap;
          width: 100%;
        }
      }
    }
  }
}
</style>
